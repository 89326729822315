$(document).ready(function () {
    var zindex = 10;
    $(".product-card").click(function (e) {
        e.preventDefault();
        var isShowing = false;
        if ($(this).hasClass("show")) {
            isShowing = true
        }
        if ($(".product-container").hasClass("showing")) {
            $(".product-card.show").removeClass("show");
            if (isShowing) {
                $(".product-container").removeClass("showing");
            } else {
                $(this).css({ zIndex: zindex }).addClass("show");
            }
            zindex++;
        } else {
            $(".product-container").addClass("showing");
            $(this).css({ zIndex: zindex }).addClass("show");
            zindex++;
        }

    });
    $('.counter').counterUp({
        delay: 10,
        time: 2000
      });
      $('.counter').addClass('animated fadeInDownBig');
      $('h3').addClass('animated fadeIn');
});

