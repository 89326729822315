import Swiper from 'swiper'

var swiper2 = new Swiper(".swiper-1", {
    effect: "cards",
    grabCursor: true,
    centeredSlides: true,
    autoplay: {
        delay: 300,
        disableOnInteraction: false
    },
    keyboard: {
        enabled: true
    },
    mousewheel: {
        thresholdDelta: 70
    },
    loop: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true
    },
    navigation: {
        enabled: true,
        nextEl: " .swiper-button-next",
        prevEl: ".swiper-button-prev"
    },
    breakpoints: {
        320: {
            slidesPerView: 1.2,
        },
        640: {
            slidesPerView: 2,
            spaceBetween: 20
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 20
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        1560: {
            slidesPerView: 3,
            spaceBetween: 20
        }
    }
});


$(document).ready(function() {
    // Swiper: Slider
        new Swiper('.swiper-container', {
            loop: true,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            slidesPerView: 3,
            paginationClickable: true,
            spaceBetween: 20,
            nextEl: ".swiper-button-next",  
            prevEl: ".swiper-button-prev",
            navigation: true,
            // navigation: {
            //     enabled: true,
            //     nextEl: " .swiper-button-next",
            //     prevEl: ".swiper-button-prev"
            // },
            breakpoints: {
                1920: {
                    slidesPerView: 3,
                    spaceBetween: 30
                },
                1028: {
                    slidesPerView: 2,
                    spaceBetween: 30
                },
                767: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                480: {
                    slidesPerView: 1.2,
                    spaceBetween: 10
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                }
            }
        });
    });
    